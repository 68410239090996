import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { APP_CONFIG } from 'src/app/app-config';
import ICodeRegister from 'src/app/interfaces/ICodeRegister';
import ICodes from 'src/app/interfaces/ICodes';
import IResourceApp from 'src/app/interfaces/IResourceApp';
import IUserRegister from 'src/app/interfaces/IUserRegister';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-create-student-codes',
  templateUrl: './create-student-codes.page.html',
  styleUrls: ['./create-student-codes.page.scss'],
})
export class CreateStudentCodesPage implements OnInit {

  @Input() title:string = '';
  @Input() view:string = '';

  public userRegister:IUserRegister = {
    name: '',
    lastname_father: '',
    lastname_mother: '',
    phone: '',
    email: '',
    type: '',
    schools: []
  };

  public codeFilter:ICodes = {
    uuid: (localStorage.getItem('user_uuid')) ? localStorage.getItem('user_uuid') : '',
    level: '',
    grade: '',
    course: '',
    section: '',
    filter: false,
    type: '' // 0: normal, 1: bycode
  };
  public codesSelect:ICodes[] = [];

  public codeRegister:ICodeRegister = {
    uuid: (localStorage.getItem('user_uuid')) ? localStorage.getItem('user_uuid') : '',
    codes: [],
    level: '',
    grade: '',
    course: '',
    section: ''
  };

  public resourceApp:IResourceApp = {
    user_app_uuid: (localStorage.getItem('user_uuid')) ? localStorage.getItem('user_uuid') : '',
    title: '',
    description: '',
    url: '',
    files: [],
    type: '',
    start_date: '',
    end_date: '',
    status: '1'
  };

  public loading:boolean = false;
  public selectAll:boolean = false;
  public showAll:boolean = false;
  public code:string = '';
  public codes:any[] = [];
  public resources:any[] = [];
  public mimeTypes:any = APP_CONFIG.mimeTypes;
  public levels:any[] = APP_CONFIG.levels;
  public grades:any[] = APP_CONFIG.grades;
  public courses:any[] = APP_CONFIG.courses;
  public sections:any[] = APP_CONFIG.sections;
  public typeResources:any[] = APP_CONFIG.typeResources;

  constructor (
    public platform: Platform,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private api: ApiService,
    public globalService: GlobalService
  ) { }

  ngOnInit() {
    let _root = this;

    if(_root.view == 'showProfile') {
      if(localStorage.getItem('user_uuid')) {
        _root.loading = true;
        _root.api.profileApp(localStorage.getItem('user_uuid'), 'teacher').subscribe(
          result => {
            _root.userRegister = result;
            _root.loading = false;
          },
          error => {
            _root.loading = false;
          }
        );
      }
    }
    if(_root.view == 'managerResource') {
      if(localStorage.getItem('user_uuid')) {
        _root.loading = true;
        _root.api.resources().subscribe(
          result => {
            _root.resources = result.resources;
            _root.loading = false;
          },
          error => {
            _root.loading = false;
          }
        );
      }
    }
    _root.codeFilter.filter = _root.showAll;
  }

  dismiss() {
    let _root = this;

    _root.modalController.dismiss({
      // data: null
    });
  }

  onClickAddCode(e) {
    let _root = this;

    if(_root.code != '') {
      _root.codeRegister.codes.push( (_root.code).toLowerCase() );
      _root.code = '';
    }
  }

  onClickShowCodes(codes) {
    let _root = this;

    _root.codes = codes;
  }

  onChangeCheck(target) {
    let _root = this;
    _root.codesSelect = [];

    switch (target) {
      case 'only':
        for(let item of _root.codes) {
          if(item.select == true) {
            _root.codesSelect.push(item);
          }
        }

        if(_root.codesSelect.length == 0) {
          _root.selectAll = false;
        }
        break;

      case 'all':
        if(_root.selectAll == true) {
          for(let item of _root.codes) {
            item.select = true;
            _root.codesSelect.push(item);
          }
        }
        else {
          for(let item of _root.codes) {
            item.select = false;
          }
        }
        break;
    }
  }

  deleteCode(code:string) {
    let _root = this;

    _root.codeRegister.codes = _root.codeRegister.codes.filter(function(item) {
      return item !== code
    });
  }

  onClickResetShowCodes() {
    let _root = this;

    _root.codes = [];
  }

  onClickReset() {
    let _root = this;

    _root.codes = [];
    _root.resources = [];
    _root.codesSelect = [];
    _root.codeFilter = {
      uuid: (localStorage.getItem('user_uuid')) ? localStorage.getItem('user_uuid') : '',
      level: '',
      grade: '',
      course: '',
      section: '',
      filter: false
    };
  }

  onChange(target: string, value: any) {
    let _root = this;
    switch (target) {
      case 'resource':
        _root.resourceApp.url == '';
        _root.resourceApp.files = [];
        break;
    }
  }

  onChangeInputFile(event) {
    let _root = this;

    const files:any = (event.target as HTMLInputElement)?.files;
    if(files.length > 0) {
      _root.resourceApp.files = files;
    }
  }

  onClickRemoveItemFile() {
    let _root = this;
    _root.resourceApp.files = [];
  }

  onClickEnviar() {
    let _root = this;

    switch (_root.view) {
      case 'createCode':
        if(_root.codeRegister.level == '') {
          _root.globalService.showToast('Seleciona un nivel.');
        }
        else if(_root.codeRegister.grade == '') {
          _root.globalService.showToast('Selecciona un grado.');
        }
        else if(_root.codeRegister.course == '') {
          _root.globalService.showToast('Selecciona un curso.');
        }
        else if(_root.codeRegister.codes.length == 0) {
          _root.globalService.showToast('Ingresa como mínimo un código.');
        }
        else {
          _root.loading = true;
          _root.api.codeRegister(_root.codeRegister).subscribe(
            result => {
              _root.loading = false;
              _root.modalController.dismiss({
                // data: null
              });
            },
            error => {
              _root.loading = false;
            }
          );
        }
        break;
      case 'createResource':
        if(_root.resourceApp.title == '') {
          _root.globalService.showToast('Ingresa un título.');
        }
        else if(_root.resourceApp.description == '') {
          _root.globalService.showToast('Ingresa una descripción.');
        }
        else if(_root.resourceApp.type == '') {
          _root.globalService.showToast('Selecciona un tipo de recurso.');
        }
        // else if(_root.resourceApp.start_date == '') {
        //   _root.globalService.showToast('Ingresa una fecha de inicio.');
        // }
        else if(_root.resourceApp.end_date == '') {
          _root.globalService.showToast('Ingresa una fecha final.');
        }
        else {
          if(_root.resourceApp.type != 'file') {
            if(_root.resourceApp.url == '') {
              _root.globalService.showToast('Ingresa la URL ó LINK.');
            }
            else {
              _root.saveResourceApp();
            }
          }
          else {
            if(_root.resourceApp.files.length > 0) {
              _root.saveResourceApp();
            }
            else {
              _root.globalService.showToast('Selecciona al menos un archivo.');
            }
          }
        }
        break;
    }
  }

  saveResourceApp() {
    let _root = this;
    _root.loading = true;
    _root.api.resourceAppRegister(_root.resourceApp).subscribe(
      result => {
        _root.loading = false;
        _root.modalController.dismiss({
          // data: null
        });
      },
      error => {
        _root.loading = false;
      }
    );
  }

  onClickBuscar() {
    let _root = this;

    _root.codes = [];

    if(_root.codeFilter.filter == true) {
      _root.loading = true;
      _root.api.codes(_root.codeFilter).subscribe(
        result => {
          _root.codes = result.codes;
          for(let code of _root.codes) {
            code.select = false;
          }
          _root.loadResources();
        },
        error => {
          _root.loadResources();
        }
      );
    }
    else {
      if(_root.codeFilter.level == '') {
        _root.globalService.showToast('Seleciona un nivel.');
      }
      else if(_root.codeFilter.grade == '') {
        _root.globalService.showToast('Selecciona un grado.');
      }
      else if(_root.codeFilter.section == '') {
        _root.globalService.showToast('Selecciona una sección.');
      }
      else if(_root.codeFilter.course == '') {
        _root.globalService.showToast('Selecciona un curso.');
      }
      else {
        _root.loading = true;
        _root.api.codes(_root.codeFilter).subscribe(
          result => {
            _root.codes = result.codes;
            for(let code of _root.codes) {
              code.select = false;
            }
            _root.loadResources();
          },
          error => {
            _root.loadResources();
          }
        );
      }
    }
  }

  onClickItemCode(item) {
    let _root = this;
    _root.loading = true;
    _root.api.codeResourcesAssing(item.uuid).subscribe(
      result => {
        _root.loading = false;
        console.log(result);
      },
      error => {
        _root.loading = false;
      }
    );
  }

  loadResources() {
    let _root = this;

    _root.api.resources().subscribe(
      result => {
        for(let item of result.resources) {
          let _obj = {
            text: item.title,
            role: 'destructive',
            icon: 'logo-google',
            id: item.type,
            data: {
              uuid: item.uuid
            },
            handler: () => {
              // console.log(item);
            }
          };
          _root.resources.push(_obj);
        }
        _root.loading = false;
      },
      error => {
        _root.loading = false;
      }
    );
  }

  async onClickAssignJobs() {
    let _root = this;
    const actionSheet = await this.actionSheetController.create({
      header: 'Asignar recurso',
      cssClass: '',
      buttons: _root.resources
    });

    await actionSheet.present();

    const { role, data } = await actionSheet.onDidDismiss();

    if(data !== undefined) {
      if(data.uuid !== undefined) {
        _root.loading = true;
        _root.api.resourceAssings({uuid: (localStorage.getItem('user_uuid')) ? localStorage.getItem('user_uuid') : '', resource_app_uuid: data.uuid, codes: _root.codesSelect}).subscribe(
          result => {
            _root.loading = false;
            _root.globalService.showToast('Se asiganron los recursos.');
            setTimeout(() => {
              _root.dismiss();
            }, 2000);
          },
          error => {
            _root.loading = false;
          }
        );
      }
    }
  }

}
