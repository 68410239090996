import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-result-search-school-page',
  templateUrl: './result-search-school.page.html',
  styleUrls: ['./result-search-school.page.scss'],
})
export class ResultSearchSchoolPage implements OnInit {

  @Input() minedu_data:any = [];
  selectedItem:boolean = false;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  dismiss() {
    let _root = this;

    _root.modalController.dismiss({
      minedu_data: _root.minedu_data
    });
  }

  onCheckChange() {
    let _root = this;

    // if(!_root.selectedItem) {
    //   for(let item of _root.minedu_data) {
    //     if(item.CHECK) {
    //       _root.selectedItem = true;
    //     }
    //     else {
    //       item.SHOW = false;
    //     }
    //   }
    // }
    // else {
    //   for(let item of _root.minedu_data) {
    //     item.SHOW = true;
    //   }
    //   _root.selectedItem = false;
    // }
  }

}
