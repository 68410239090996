// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .item-code .item-code-check {
  display: flex;
  align-items: center;
  justify-content: center;
}
:host .item-code .item-code-label {
  display: flex;
  align-items: center;
}
:host .item-code .item-code-label ion-chip {
  width: 100%;
}
:host .item-code .item-code-opt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.5em;
}
:host .options-code {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/create-student-codes/create-student-codes.page.scss"],"names":[],"mappings":"AAEQ;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AADZ;AAGQ;EACI,aAAA;EACA,mBAAA;AADZ;AAEY;EACI,WAAA;AAAhB;AAGQ;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AADZ;AAKI;EACI,yBAAA;EACA,eAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;AAHR","sourcesContent":[":host {\n    .item-code {\n        .item-code-check {\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n        .item-code-label {\n            display: flex;\n            align-items: center;\n            ion-chip {\n                width: 100%;\n            }\n        }\n        .item-code-opt {\n            display: flex;\n            align-items: center;\n            justify-content: flex-end;\n            font-size: 1.5em;\n        }\n    }\n\n    .options-code {\n        background-color: #ffffff;\n        position: fixed;\n        bottom: 0;\n        left: 0;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
