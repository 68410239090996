import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { GlobalService } from './services/global.service';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private screenOrientation: ScreenOrientation,
    private router: Router,
    private api: ApiService,
    public globalService: GlobalService
  ) {
    if(this.platform.is('capacitor')) {
      this.platform.ready().then(() => {
        // this.statusBar.hide();

        let platformDevice = this.globalService.getDeviceType();
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#3b7ca7');

        if(platformDevice == 'tablet') {
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
        }
        else if(platformDevice == 'mobile') {
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }
        this.screenOrientation.unlock();
        // this.screenOrientation.onChange().subscribe(
        //   () => {
        //     console.log("Orientation Changed");
        //   }
        // );
      });
    }
  }

  onClickLogout() {
    let _root = this;
    
    _root.api.logout().subscribe(
      result => {
        _root.api.setTokenToLocal('');
        localStorage.removeItem('uuid_key');
        localStorage.removeItem('user_uuid');
        localStorage.removeItem('token');
        _root.globalService.is_login = false;
        _root.router.navigate(['/login']);
      },
      error => {

      }
    );
  }

  onClickMenuItem(url:string) {
    this.router.navigate([url]);
  }
}
