import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { GlobalService } from '../services/global.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard  {
    constructor(
        private router: Router,
        private api: ApiService,
        private globalService: GlobalService
    ) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        return new Promise((resolve, reject) => {
            let _root = this;
            _root.api.profile().subscribe(
                result => {
                    if( result['profile'] ) {
                        resolve(true);
                        localStorage.setItem('uuid_key', result['profile']['uuid']);
                        _root.globalService.profile = result['profile'];
                        _root.globalService.is_login = true;
                    }
                    else {
                        resolve(false);
                        _root.globalService.profile = null;
                        localStorage.setItem('uuid_key', '');
                        _root.api.setTokenToLocal('');
                        _root.router.navigate(['/']);
                    }
                },
                error => {
                    resolve(false);
                    _root.globalService.profile = null;
                    localStorage.setItem('uuid_key', '');
                    _root.api.setTokenToLocal('');
                    _root.router.navigate(['/login']);
                }
            );
            
        });
    }
}