import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_APIS } from '../app.apis';
import IUserRegister from '../interfaces/IUserRegister';
import ICodeRegister from '../interfaces/ICodeRegister';
import ICodes from '../interfaces/ICodes';
import IResourceApp from '../interfaces/IResourceApp';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) {
    
  }

  // AUTH
  register(register:IUserRegister): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.post(APP_APIS.register, register, {headers: headers});
  }

  login(name:string, password:string, target:string) : Observable<any> {
    let headers = new HttpHeaders({
      'Accept' : 'application/json'
    }); 
    let formData = new FormData();
    formData.append('name', name);
    formData.append('password', password);
    formData.append('target', target);
    return this.http.post(APP_APIS.login, formData, {headers: headers});
  }

  // AUTH
  profileApp(uuid:string, type:string): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.post(APP_APIS.profileApp, {uuid, type}, {headers: headers});
  }

  loginApp(email:string, target:string) : Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    }); 
    let formData = new FormData();
    formData.append('email', email);
    formData.append('target', target);
    return this.http.post(APP_APIS.loginApp, formData, {headers: headers});
  }

  logout() : Observable<any> {
    localStorage.removeItem('books');
    localStorage.removeItem('tree');
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });
    let formData = new FormData();
    formData.append('target', 'mobile');
    return this.http.post(APP_APIS.logout, formData, {headers: headers});
  }

  // CODE
  codes(data:ICodes): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(APP_APIS.codes, data, {headers: headers});
  }
  
  codeRegister(data:ICodeRegister): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });
    
    return this.http.post(APP_APIS.code_register, data, {headers: headers});
  }

  codeResourcesAssing(uuid:any) : Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(`${APP_APIS.code_resources_assign}`, {uuid}, {headers});
  }
  
  // RESOURCES
  resources(): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(APP_APIS.resources, {uuid: (localStorage.getItem('user_uuid')) ? localStorage.getItem('user_uuid') : ''}, {headers: headers});
  }

  resourceAppRegister(data:IResourceApp): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    let formData = new FormData();
    formData.append('user_app_uuid', data.user_app_uuid);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('url', data.url);
    for(let file of data.files) {
      formData.append('files', file);
    }
    formData.append('type', data.type);
    formData.append('start_date', data.start_date);
    formData.append('end_date', data.end_date);
    formData.append('status', data.status);

    return this.http.post(APP_APIS.resource_register, formData, {headers: headers});
  }
  
  resourceAssings(data:any): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(APP_APIS.resource_assings, data, {headers: headers});
  }

  setTokenToLocal(token) {
    localStorage.setItem('token', token);
  }

  // USER
  profile() {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.get(`${APP_APIS.profile}`, {headers: headers});
  }

  virtualbooks() : Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.get(`${APP_APIS.virtualbooks}`, {headers: headers});
  }

  // FILES
  getVirtualBookPage(uuid, page) : Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(`${APP_APIS.virtualbook_page}${uuid}/${page}`, {}, {responseType: 'arraybuffer', headers: headers});
  }

  // EXTERNAL SERVICES
  getMineduSchool(data) : Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(`${APP_APIS.minedu}`, data, {headers});
  }

  // UBIGEO
  getDepartments(): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      // 'Authorization': `Bearer ${token}`
    });

    return this.http.get(`${APP_APIS.departmentso}`, {headers: headers});
  }

  getProvinces(data): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      // 'Authorization': `Bearer ${token}`
    });

    return this.http.get(`${APP_APIS.provinceso}${data}`, {headers: headers});
  }

  getDistricts(data): Observable<any> {
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Accept' : 'application/json',
      // 'Authorization': `Bearer ${token}`
    });

    return this.http.get(`${APP_APIS.districtso}${data}`, {headers: headers});
  }

  // DB LOCAL

}
