import { Injectable } from '@angular/core';
import { getPlatforms, Platform } from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { ToastController } from '@ionic/angular';
import { APP_CONFIG } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  is_login:boolean = false;
  profile:any = null;

  constructor(
    private fileTransfer: FileTransfer,
    private file: File,
    private toastCtrl: ToastController
  ) {
    console.log(this.file.applicationDirectory);
  }

  getDeviceType() {
    let _root = this;
    let platformDevice = '';

    if(getPlatforms().join('-').search(/desktop/i) != -1) {
      platformDevice = 'desktop';
    }
    if(getPlatforms().join('-').search(/mobile/i) != -1) {
      platformDevice = 'mobile';
    }
    if(getPlatforms().join('-').search(/tablet/i) != -1) {
      platformDevice = 'tablet';
    }

    return platformDevice;
  }

  validateCellPhoneNumber(value:any, type:string = 'cell') {
    let response = false;
    let regex = /\d{9}/;

    if( type == 'phone' ) {
        regex = /\d{7}/;
    }

    let validate = regex.test(value);
    if( validate ) {
        if(type == 'cell') {
            if( value.length == 9 ) {
                response = true;
            }
        }
        else if(type == 'phone') {
            if( value.length == 7 ) {
                response = true;
            }
        }
    }

    return response;
  }

  validateEmail(value) {

    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    if (value.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  validateUrl(value) {
    
  }

  checkIconTypeResource(type) {
    let _root = this;

    let _obj:any = {};
    for (let i = 0; i < APP_CONFIG.typeResources.length; i++) {
        if (APP_CONFIG.typeResources[i].id == type) {
          _obj = APP_CONFIG.typeResources[i];
        }
    }

    return (_obj.icon !== undefined) ? _obj.icon : '';
  }

  async showToast(message:string, duration:number = 2000) {
    let _root = this;
    const toast = await _root.toastCtrl.create({
      message,
      duration
    });
    await toast.present();
  }

  formatFileSize(bytes, decimalPoint) {
    if(bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
